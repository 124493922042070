import Service from "./Service";

const resource = "api/triage";

export default {
  store(data) {
    return Service.post(`${resource}/store`, data);
  },
  show(id) {
    return Service.get(`${resource}/show/${id}`);
  },
  showByIdUser(id) {
    return Service.get(`${resource}/show-by-idUser/${id}`);
  },
  showByIdUserAtencion(id) {
    return Service.get(`${resource}/show-by-idUser-atencion/${id}`);
  },
};
