<template>
  <main>
    <div class="form-group">
      <label class="form-label">Diagnostico </label>
      <v-select-dx v-model="diagnostico" mode="object"></v-select-dx>
    </div>
    <div class="form-group">
      <button class="btn btn-primary btn-sm" @click="agregarDiagnostico()">
        <i class="fas fa-plus"></i>&nbsp;Agregar
      </button>
    </div>
    <div class="table-responsive mt-4">
      <table class="table table-borderless mb-0 table-sm">
        <thead class="border-bottom bg-light">
          <tr class="small text-uppercase text-muted">
            <th scope="col">Codigo</th>
            <th scope="col">Diagnostico</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-bottom"
            v-for="(item, index) in items"
            :key="item.id"
          >
            <td>{{ item.ID_DIAG }}</td>
            <td class="font-weight-bold">{{ item.DESC_DIAG }}</td>
            <td>
              <button
                class="btn btn-outline-danger btn-sm"
                @click="removerServicio(index)"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-group mt-4">
      <label class="form-label">Observaciones </label>
      <div class="">
        <textarea
          v-model="observaciones"
          class="form-control"
          :class="{
            'is-invalid': $v.observaciones.$error && $v.observaciones.$dirty,
          }"
        ></textarea>
      </div>
    </div>
  </main>
</template>
<script>
import vSelectDx from "../../../../components/common/VSelectDx";
import impresionDxService from "../../../../services/impresionDxService";
//import $ from 'jquery';
import { required, minLength } from "vuelidate/lib/validators";
import { isEmpty } from "lodash";
import Toast from "../../../../components/common/utilities/toast";
import diagnosticoService from "../../../../services/diagnosticoService";
import urgenciaTriageService from "../../../../services/urgenciaTriageService";
export default {
  props: ["idHistoria", "finalizada", "dx_ppal", "modo", "idUsuario"],
  components: { vSelectDx },

  data() {
    return {
      id_historia: "",
      id_impresion_dx: "",
      observaciones: "",
      diagnostico: {},
      items: [],
    };
  },
  validations() {
    return {
      observaciones: { required },
      items: {
        required,
        minLength: minLength(1),
      },
    };
  },
  methods: {
    agregarDiagnostico() {
      if (isEmpty(this.diagnostico)) {
        Toast.fire({
          icon: "error",
          title: "seleccione un diagnostico",
        });
        return;
      }
      if (
        this.items.some((item) => item.ID_DIAG === this.diagnostico.ID_DIAG)
      ) {
        Toast.fire({
          icon: "error",
          title: "El diagnóstico ya fue agregado",
        });
        return;
      } else {
        this.items.push({ ...this.diagnostico });
      }
    },
    removerServicio(index) {
      this.items.splice(index, 1);
    },
    async save() {
      try {
        if (isEmpty(this.items)) {
          Toast.fire({
            icon: "error",
            title: "Debe agregar por lo menos un diagnostico",
          });
          return;
        }
        this.$v.observaciones.$touch();
        if (this.$v.observaciones.$invalid) {
          return;
        }
        let impresionDx = {
          id_historia: this.idHistoria,
          observaciones: this.observaciones,
          item_impresion_dx: this.items,
        };

        this.LoaderSpinnerShow();

        if (this.id_impresion_dx !== "") {
          impresionDx.id = this.id_impresion_dx;
          await impresionDxService.update(impresionDx);
        } else {
          await impresionDxService.store(impresionDx);
          const response = await impresionDxService.showByHc(this.idHistoria);
          this.id_impresion_dx = response.data.id;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
      } catch (error) {
        console.error(error);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    async consultarTriage(id) {
      if (this.modo != undefined && this.modo === "URGENCIA") {
        const response_triage =
          await urgenciaTriageService.showByIdUserAtencion(id);

        if (!isEmpty(response_triage.data)) {
          this.items = response_triage.data.diagnosticos.map((x) => {
            return {
              ID_DIAG: x.diagnostico.ID_DIAG,
              DESC_DIAG: x.diagnostico.DESC_DIAG,
            };
          });
        }
      }
    },
  },
  watch: {
    finalizada: async function (value) {
      if (value === "N") {
        const response = await impresionDxService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
          this.observaciones = response.data.observaciones;
          this.id_impresion_dx = response.data.id;
          //this.items = response.data.items;
          this.items = response.data.items.map((x) => {
            return {
              ID_DIAG: x.id_diagnostico,
              DESC_DIAG: x.diagnostico.DESC_DIAG,
            };
          });
        } else if (this.dx_ppal != undefined) {
          const response_dx = await diagnosticoService.show(this.dx_ppal);
          this.items = response_dx.data.map((x) => {
            return {
              ID_DIAG: x.ID_DIAG,
              DESC_DIAG: x.DESC_DIAG,
            };
          });
        } else {
          this.consultarTriage(this.idUsuario);
        }
      }
    },
    idUsuario: function (value) {
      this.consultarTriage(value);
    },
  },
  async created() {
    if (this.finalizada === "N") {
      const response = await impresionDxService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.observaciones = response.data.observaciones;
        this.id_impresion_dx = response.data.id;
        //this.items = response.data.items;
        this.items = response.data.items.map((x) => {
          return {
            ID_DIAG: x.id_diagnostico,
            DESC_DIAG: x.diagnostico.DESC_DIAG,
          };
        });
      } else if (this.dx_ppal != undefined) {
        const response_dx = await diagnosticoService.show(this.dx_ppal);
        this.items = response_dx.data.map((x) => {
          return {
            ID_DIAG: x.ID_DIAG,
            DESC_DIAG: x.DESC_DIAG,
          };
        });
      } else {
        this.consultarTriage(this.idUsuario);
      }
    }
  },
};
</script>
